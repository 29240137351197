<template>
  <v-card class="mx-auto" :disabled="loading" :loading="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'facturacion.ordenes_pago',
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${
                loading
                  ? ''
                  : table_data.length > 0
                  ? `(${start_date} | ${end_date} | ${table_data.length} regs.)`
                  : ''
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row v-if="!table_data.length" dense>
        <v-col cols="12" sm="12" md="3">
          <v-file-input
            v-model="document_status_account_0"
            accept=".xlsx"
            label="Archivo"
            dense
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <DatePicker
            :label="`Fecha inicio`"
            :model.sync="start_date"
            all_date
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <DatePicker :label="`Fecha final`" :model.sync="end_date" all_date />
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <v-btn block small color="primary" @click.prevent="getData()">
            Cargar
            <v-icon right v-text="'mdi-check'" />
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else dense>
        <v-col cols="12" xs="12" md="9">
          <v-text-field
            v-model="table_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-btn block small color="default" @click="table_data = []">
            Cargar otro archivo
            <v-icon right v-text="'mdi-file'" />
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :items="table_data"
            :search="table_search"
            :headers="table_headers"
            :loading="loading"
            :item-class="tableRowBg"
            :items-per-page="-1"
            dense
          >
            <template v-slot:item.importe="{ item }">
              {{ numberFormat(item.importe) }}
            </template>
            <template v-slot:item.amount="{ item }">
              {{ item.amount ? numberFormat(item.amount) : "" }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip v-if="!item.rha_bill_insurance_payment_folio" left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    fab
                    x-small
                    color="warning"
                    dark
                    @click.prevent="insurancePaymentDialog(item)"
                  >
                    <v-icon> mdi-magnify-plus </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Buscar pago'" />
              </v-tooltip>
              <v-tooltip
                v-if="item.insurance_payment_check && item.stamp_id"
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    fab
                    x-small
                    color="success"
                    dark
                    @click.prevent="downloadFile(item.stamp_id, 'pdf')"
                  >
                    <v-icon> mdi-file </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Complemento de pago PDF'" />
              </v-tooltip>
              <v-tooltip
                v-if="item.insurance_payment_check && !item.stamp_id"
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn class="mr-1" v-on="on" fab x-small color="error" dark>
                    <v-icon> mdi-alert </v-icon>
                  </v-btn>
                </template>
                <span v-text="'No se encuentra complemento de pago'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn small color="success" @click.prevent="saveData">
            Guardar información
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- DIALOGS START-->
    <v-dialog
      v-model="insurance_payment_dialog"
      scrollable
      persistent
      max-width="1800px"
    >
      <v-card
        tile
        :disabled="insurance_payment_dialog_loading"
        :loading="insurance_payment_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> Buscar pago </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insurance_payment_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="insurance_payment_table_data.length > 0">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'DETALLE'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Fila</th>
                              <th>Fecha</th>
                              <th>Concepto</th>
                              <th>Referencia</th>
                              <th>Referencia Ampliada</th>
                              <th>Abono</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td v-text="insurance_payment_data.index" />
                              <td v-text="insurance_payment_data.fila" />
                              <td v-text="insurance_payment_data.fecha" />
                              <td v-text="insurance_payment_data.concepto" />
                              <td v-text="insurance_payment_data.referencia" />
                              <td
                                v-text="
                                  insurance_payment_data.referencia_ampliada
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(insurance_payment_data.importe)
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              v-if="insurance_payment_data.rha_bill_payment_items.length > 0"
              cols="12"
            >
              <v-card class="mx-auto">
                <v-card-title>
                  <h2
                    class="text-caption"
                    v-text="
                      `P. ENCONTRADOS (${insurance_payment_data.rha_bill_payment_items.length})`
                    "
                  />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Folio</th>
                              <th>Doc. ID</th>
                              <th>Pago ID</th>
                              <th>Factura SM</th>
                              <th>Núm. trámite</th>
                              <th>Núm. siniestro</th>
                              <th>Monto a pagar</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                rha_bill_payment_item, i
                              ) in insurance_payment_data.rha_bill_payment_items"
                              :key="i"
                            >
                              <td v-text="rha_bill_payment_item.index" />
                              <td v-text="rha_bill_payment_item.rha_folio" />
                              <td
                                v-text="rha_bill_payment_item.rha_bill_folio"
                              />
                              <td
                                v-text="
                                  rha_bill_payment_item.rha_bill_insurance_payment_folio
                                "
                              />
                              <td v-text="rha_bill_payment_item.stamp_folio" />
                              <td
                                v-text="
                                  rha_bill_payment_item.insurance_procedure
                                "
                              />
                              <td
                                v-text="rha_bill_payment_item.sinister_number"
                              />
                              <td
                                v-text="
                                  numberFormat(rha_bill_payment_item.amount)
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2
                    class="text-caption"
                    v-text="
                      `PAGOS ASEGURADORA (${start_date} | ${end_date} | ${insurance_payment_table_data.length} regs.)`
                    "
                  />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        v-model="insurance_payment_table_search"
                        append-icon="mdi-magnify"
                        label="Buscar..."
                        single-line
                        hide-details
                        dense
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-data-table
                        :items="insurance_payment_table_data"
                        :search="insurance_payment_table_search"
                        :headers="insurance_payment_table_headers"
                        :loading="insurance_payment_dialog_loading"
                        :items-per-page="-1"
                        dense
                      >
                        <template v-slot:item.amount="{ item }">
                          {{ numberFormat(item.amount) }}
                        </template>
                        <template v-slot:item.action="{ item }">
                          <v-tooltip left>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="mr-1"
                                v-on="on"
                                fab
                                x-small
                                color="warning"
                                dark
                                @click.prevent="insurancePayment(item)"
                              >
                                <v-icon> mdi-send </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Asignar'" />
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOGS END-->
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  dateTimeNow,
  toFormData,
  rules,
  msgConfirm,
  msgAlert,
} from "../../control";
import DatePicker from "../../components/DatePicker.vue";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    DatePicker,
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      loading: false,
      start_date: null,
      end_date: null,
      document_status_account_0: null,
      table_data: [],
      table_search: "",
      table_headers: [
        { text: "#", filterable: true, value: "index" },
        { text: "Fila", filterable: true, value: "fila" },
        { text: "Fecha", filterable: true, value: "fecha" },
        { text: "Concepto", filterable: true, value: "concepto" },
        { text: "Referencia", filterable: true, value: "referencia" },
        {
          text: "Referencia Ampliada",
          filterable: true,
          value: "referencia_ampliada",
        },
        { text: "Abono", filterable: true, value: "importe" },
        { text: "P. encontrados", filterable: true, value: "exist" },
        { text: "Folio", filterable: true, value: "rha_folio" },
        { text: "Doc ID", filterable: true, value: "rha_bill_folio" },
        {
          text: "Pago ID",
          filterable: true,
          value: "rha_bill_insurance_payment_folio",
        },
        { text: "Factura SM", filterable: true, value: "stamp_folio" },
        {
          text: "Núm. trámite",
          filterable: true,
          value: "insurance_procedure",
        },
        { text: "Núm. siniestro", filterable: true, value: "sinister_number" },
        { text: "Monto a pagar", filterable: true, value: "amount" },
        {
          text: "",
          value: "action",
          sortable: false,
          width: "50px",
        },
      ],
      rules: rules(),
      insurance_payment_data: null,
      insurance_payment_dialog: false,
      insurance_payment_dialog_loading: false,
      insurance_payment_table_data: [],
      insurance_payment_table_search: "",
      insurance_payment_table_headers: [
        { text: "#", filterable: true, value: "index" },
        { text: "Folio", filterable: true, value: "rha_folio" },
        { text: "Doc. ID", filterable: true, value: "rha_bill_folio" },
        {
          text: "Pago ID",
          filterable: true,
          value: "rha_bill_insurance_payment_folio",
        },
        { text: "Factura SM", filterable: true, value: "stamp_folio" },
        {
          text: "Núm. trámite",
          filterable: true,
          value: "insurance_procedure",
        },
        { text: "Núm. siniestro", filterable: true, value: "sinister_number" },
        { text: "Monto a pagar", filterable: true, value: "amount" },
        {
          text: "",
          value: "action",
          sortable: false,
          width: "50px",
        },
      ],
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    tableRowBg: function (item) {
      if (item.insurance_payment_check) return "blue lighten-3";
      if (!item.rha_bill_insurance_payment_id) return "red lighten-3";
      if (item.rha_bill_insurance_payment_id && item.exist == 1)
        return "green lighten-3";
      if (item.rha_bill_insurance_payment_id && item.exist != 1)
        return "orange lighten-3";
    },
    getData() {
      if (this.document_status_account_0 != null) {
        this.loading = true;
        this.table_data = [];

        Axios.post(
          URL_API + "/bills/rha/payment/status_account",
          toFormData({
            document_status_account_0: this.document_status_account_0,
            start_date: this.start_date,
            end_date: this.end_date,
          }),
          headersToken(this.login.token)
        )
          .then((resp) => {
            if (resp.data.success) {
              if (resp.data.data.length > 0) {
                this.table_data = resp.data.data;
              } else {
                this.$swal.fire(
                  msgAlert(
                    "warning",
                    "Sin coincidencias para el intervalo de tiempo señalado"
                  )
                );
              }
            } else {
              console.log(res.data.message);
              this.$swal.fire(msgAlert("error", "Formato de archivo invalido"));
            }
            this.loading = false;
          })
          .catch((e) => {
            console.log(e);
            this.$swal.fire(msgAlert("error", "Error en lectura de archivo"));
            this.loading = false;
          });
      } else {
        this.$swal.fire(msgAlert("error", "Selecciona un archivo"));
      }
    },
    insurancePaymentDialog(item) {
      this.insurance_payment_data = null;
      this.insurance_payment_table_data = [];
      this.insurance_payment_dialog = true;
      this.insurance_payment_dialog_loading = true;

      Axios.post(
        URL_API + "/rha/bills/insurance/payment/search",
        {
          start_date: this.start_date,
          end_date: this.end_date,
        },
        headersToken(this.login.token)
      ).then((resp) => {
        if (resp.data.success) {
          if (resp.data.data.length > 0) {
            this.insurance_payment_data = item;
            this.insurance_payment_table_data = resp.data.data;
          } else {
            this.$swal.fire(
              msgAlert(
                "warning",
                "Sin coincidencias para el intervalo de tiempo señalado"
              )
            );

            this.insurance_payment_dialog = false;
          }
        } else {
          console.log(resp.data.message);
          this.$swal.fire(msgAlert("error", resp.data.message));
          this.insurance_payment_dialog = false;
        }

        this.insurance_payment_dialog_loading = false;
      });
    },
    insurancePayment(item) {
      this.$swal
        .fire(msgConfirm(`¿Confirma la asignación del pago?`))
        .then((resp) => {
          if (resp.isConfirmed) {
            this.insurance_payment_dialog_loading = true;

            const index = this.table_data.findIndex(
              (v) => v.index == this.insurance_payment_data.index
            );

            this.table_data[index].rha_bill_insurance_payment_id = item.id;
            this.table_data[index].rha_bill_insurance_payment_folio =
              item.rha_bill_insurance_payment_folio;
            this.table_data[index].rha_folio = item.rha_folio;
            this.table_data[index].rha_bill_folio = item.rha_bill_folio;
            this.table_data[index].amount = item.amount;

            this.insurance_payment_dialog = false;
          }
        });
    },
    saveData() {
      this.$swal
        .fire(msgConfirm(`¿Confirma guardar la información?`))
        .then((resp) => {
          if (resp.isConfirmed) {
            this.loading = true;

            Axios.post(
              URL_API + "/bills/rha/payment/paid/check",
              toFormData({
                rha_bill_insurance_payments: this.table_data,
                document_rha_bill_insurance_payments_0:
                  this.document_status_account_0,
              }),
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.getData();
              } else {
                console.log(resp.data.message);
                this.loading = false;
              }
            });
          }
        });
    },
    downloadFile(stamped_id, extension) {
      this.loading = true;

      Axios.get(
        URL_API + `/bills/stamped/${stamped_id}/files/${extension}/1`,
        headersToken(this.login.token)
      ).then((response) => {
        this.$swal.fire(
          msgAlert(
            response.data.success ? "success" : "error",
            response.data.message
          )
        );

        if (response.data.success) {
          const linkSource = `data:application/${extension};base64,${response.data.data}`;
          const downloadLink = document.createElement("a");
          const fileName = `sm_bill_${stamped_id}.${extension}`;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          console.log(response.data.message);
        }

        this.loading = false;
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    const dateTime = dateTimeNow();
    this.start_date = dateTime.substring(0, 10);
    this.end_date = dateTime.substring(0, 10);
  },
};
</script>